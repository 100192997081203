<!-- 分页组件 -->
<template>
  <div class="listPageContainer">
    <div
      class="flexRowS pageLabel"
      :class="[!$attrs.tabslist || $attrs.tabslist.length === 0 ? 'mb31' : '']"
    >
      {{ $attrs.title }}
    </div>
    <div class="blockContainer settingPage">
      <div class="detailListContainer">
        <template v-if="$attrs.tabslist && $attrs.tabslist.length > 0">
          <div class="flexRowB topSearchContainerWrap">
            <template v-if="!$attrs.select">
              <div class="flexRowS bookTypeListContainer">
                <div
                  v-for="bookType in $attrs.tabslist"
                  :key="bookType.id"
                  class="flexRow cursor bookTypeItem mr25"
                  :class="[bookType.id === active ? 'bookTypeItemActive' : '']"
                  @click="changeBookType(bookType.id)"
                >
                  {{ getTitle(bookType) }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="flexRowS selectInputWrap">
                <select v-model="selectactive" class="cursor selectInput">
                  <option
                    v-for="item in $attrs.tabslist"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div class="selectRightIcon" />
              </div>
            </template>
            <div v-if="$attrs.search" class="flexRow">
              <div
                class="flexRowSB cursor pl35 pr24 searchContainer"
                style="position: relative"
              >
                <span class="searchInputWrap">
                  <input
                    v-model="keyWords"
                    @keyup.enter="searchByHandler"
                    placeholder="请输入搜索内容"
                  />
                </span>
                <div class="searchIconNav" @click="searchByHandler">
                  <img src="@/assets/index/sousuo@2x.png" alt />
                </div>
              </div>
            </div>
            <slot name="ext" />
          </div>
        </template>
        <div
          class="flexRowB topSearchContainerWrap mb20"
          v-if="$attrs.onlysearch"
        >
          <div></div>
          <div v-if="$attrs.search" class="flexRow">
            <div
              class="flexRowSB cursor pl35 pr24 searchContainer"
              style="position: relative"
            >
              <span class="searchInputWrap">
                <input
                  v-model="onSearchKeyWords"
                  @keyup.enter="searchByHandler"
                  placeholder="请输入搜索内容"
                />
              </span>
              <div class="searchIconNav" @click="searchByHandler">
                <img src="@/assets/index/sousuo@2x.png" alt />
              </div>
            </div>
          </div>
        </div>

        <div class="flexRowSS listWrapContainer">
          <template v-if="$attrs.listItem && $attrs.listItem.length">
            <template
              v-for="(item, index) in $attrs.listItem"
              :key="item.resourceId"
            >
              <slot
                name="renderItem"
                :item="item"
                :index="index"
                :type="active"
              />
            </template>
          </template>
          <template v-else-if="list && list.length > 0">
            <template
              v-for="(item, index) in list"
              :key="item.resourceId || item.id || item.Id"
            >
              <slot
                name="renderItem"
                :item="item"
                :index="index"
                :type="active"
              />
            </template>
          </template>
          <template v-else>
            <div class="emptyContainer">
              <img src="@/assets/base/empty@2x.png" alt />
              <div class="messageText">很抱歉，暂未找到相关内容</div>
            </div>
          </template>
        </div>
        <div
          class="pagerInfoContainer mt20"
          v-if="queryInfo.total > 0 && queryInfo.total"
        >
          <!-- 分页组件 -->
          <MyPager
            @currentPage="changePageNum"
            :config="{
              total: queryInfo.total,
              pageSize: queryInfo.pageSize,
              pages: queryInfo.pages,
            }"
          ></MyPager>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Toast } from "vant";

import { baseLoad } from "@/api/index";

import MyPager from "./MyPager.vue";

Vue.use(Toast);

export default {
  name: "BaseListPager",
  components: {
    MyPager,
  },
  data() {
    return {
      onSearchKeyWords: "",
      keyWords: "",
      display: "block",
      edit: false,
      list: [],
      loading: false,
      finished: false,
      queryInfo: {
        pageSize: 12,
        pageNum: 0,
        pages: 0,
        total: 0,
      },
      filterMap: {
        0: { 0: 0 },
        1: { 1: 1 },
        2: { 2: 2 },
        3: { 3: 3 },
        4: { 4: 4 },
        5: { 5: 5 },
        6: { 6: 6 },
      },
      active: "",
      selectactive: this.$attrs.defalutactive || "",
      countMap: {},
    };
  },
  mounted() {
    if (this.$attrs.tabslist && this.$attrs.tabslist[0]) {
      this.active = this.$attrs.tabslist[0].id;
    }
  },
  computed: {
    reloadData() {
      return (
        JSON.stringify(this.$attrs.query) +
        "_" +
        this.$attrs.booktype +
        "_" +
        this.active +
        "_" +
        this.selectactive
      );
    },
  },
  created() {
    this.$watch(
      "$attrs.tabslist",
      (newValue) => {
        if (this.$attrs.tabslist) {
          this.active = (
            (this.$attrs.tabslist || []).filter((i) => i.checked)[0] || {}
          ).id;
        }
      },
      {
        immediate: true,
      }
    );
    this.$watch(
      "reloadData",
      (newValue) => {
        // console.log("beforesearchbeforesearchbeforesearch", newValue)
        // this.list = [];
        this.queryInfo.pageNum = 0;
        this.searchBook();
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    getTitle(item) {
      if (item.countProp) {
        return `${item.name}(${this.countMap[item.countProp] || 0})`;
      }
      return item.name;
    },
    changePageNum(pageNum) {
      this.queryInfo.pageNum = pageNum - 1;
      this.list = [];
      this.searchBook();
    },
    searchByHandler() {
      console.log(">>>>");
      this.list = [];
      this.queryInfo.pageNum = 0;
      this.searchBook();
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goPage(url: String) {
      if (!url) return;
      this.$router.push(url);
    },
    onClickRight() {
      this.edit = !this.edit;
    },
    deletebook(data: any) {
      console.log(data);
    },
    changeBookType(id) {
      this.active = id;
      this.$emit("changeBookType", id);
    },
    async searchBook() {
      if (this.$attrs.searchconfig) {
        let queryConfig = {
          params: {
            pageNum: this.queryInfo.pageNum + 1,
            selectKey: this.selectactive,
            active: this.active,
            objectType: this.active,
            ObjectType: this.active,
            pageSize: this.$attrs.pagesize || this.queryInfo.pageSize,
            ...this.$attrs.query,
            [this.$attrs.keywords || `keyWords`]:
              this.keyWords || this.onSearchKeyWords,
          },
          method: "get",
          ...this.$attrs.searchconfig,
        };
        if (
          this.$attrs.beforesearch &&
          typeof this.$attrs.beforesearch === "function"
        ) {
          queryConfig = this.$attrs.beforesearch(
            JSON.parse(JSON.stringify(queryConfig))
          );
          if (!queryConfig) return;
        }
        const {
          code,
          msg,
          rows,
          data,
          total,
          pageNum,
          params = {},
        } = await baseLoad(queryConfig);
        let result = rows || data;
        if (code === 200) {
          if (
            this.$attrs.aftersearch &&
            typeof this.$attrs.aftersearch === "function"
          ) {
            result = this.$attrs.aftersearch(
              result,
              params,
              queryConfig.params
            );
          }
          this.list = result;
          this.queryInfo.total = total;
          this.queryInfo.pages = pageNum;
          this.countMap = params;
          return Promise.resolve(rows);
        } else {
          Toast(msg);
          this.countMap = {};
          return Promise.resolve([]);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.topSearchContainerWrap {
  .searchInputWrap {
    display: inline-block;
    width: 100%;
    height: 100%;
    input {
      display: inline-block;
      width: 100%;
      height: 100%;
      outline: none;
    }
  }
  .selectInputWrap {
    padding: 0 18px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    margin-top: 29px;
    margin-bottom: 30px;
    .selectInput {
      width: 150px;
      height: 38px;
      outline: none;
      appearance: none;
    }
  }

  .bookTypeListContainer {
    border: 1 solid #f0a;
    margin-top: 29px;
    margin-bottom: 30px;
    .bookTypeItem {
      width: 90px;
      height: 34px;
      color: #666666;
      background: #fff;
      border: 1px dashed #dcdcdc;
      border-radius: 19px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 500;

      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #088190;
    }

    .bookTypeItemActive {
      background: #27a3b2;
      box-shadow: 1px 2px 6px 0px rgba(121, 164, 169, 0.74);
      border: 1px solid #ffffff00;
      color: #ffffff;
    }
  }
  .searchContainer {
    width: 263px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 17px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    position: relative;

    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;

    .searchIconNav {
      width: 18px;
    }
  }
  .editText {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #088190;
    margin-left: 32px;
  }
}

.baseButton {
  width: 245px;
  height: 74px;
  background: #088190;
  border-radius: 37px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.tabItemLabel {
  font-size: 32px;
  font-weight: 500;
  color: #a1a9b0;
}
.van-tab--active {
  .tabItemLabel {
    color: #088190;
  }
}
.bottomFixedContainer {
  height: 121px;
  background: #ffffff;
  box-shadow: 0px 2px 18px 0px rgba(20, 20, 20, 0.16);
}
.editTextButton {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #222222;
}
.listPageContainer {
  padding: 0px;
  background: #fff;
  width: 100%;
  .pageLable {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #222222;
  }
}
.pageLabel {
  font-weight: bolder;
}
.settingPage {
  border: 0px solid #00f;
  padding: 0px 0;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  background: #fff;
  position: relative;
  .topMargin {
    height: 10px;
    background: #f4f4f4;
  }
  .listWrapContainer {
    margin-top: 0px;
    flex-wrap: wrap;
    .bookList {
      border: 1px solid #f0a;
      .listBookItem {
        width: 196px;
        margin-right: 44px;
        overflow: hidden;
        &:nth-child(4n) {
          margin-right: 0 !important;
        }
      }
      .listBookItemRow {
        border: 0px solid #00f;
        .rowTextDetail {
          height: 91px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #666666;
          line-height: 26px;
        }
      }
    }
  }
}

.detailListContainer {
  justify-self: stretch;
  background: #fff;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  position: relative;
  overflow-y: auto;

  .navItemContainer {
    min-width: 130px;
    height: 58px;
    background: #e2eeef;
    border-radius: 29px;
  }
}

::v-deep {
  .van-tabs__wrap {
    border-bottom: 2px solid #d9dede;
  }
  .van-tabs__line {
    width: 108px;
    height: 0px;
    background: #088190;
    border-radius: 2px;
  }
  .van-tabs__wrap {
    border-bottom: 0px solid #dedcd9;
  }
  .van-cell {
    padding: 0;
  }
  .van-cell::after {
    display: none;
  }
  .categroyName {
    margin-right: 24px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .van-tabs {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    .van-tabs__wrap {
      height: 94px;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      left: 0;
      top: 0;
      z-index: 999;
      flex-grow: 0;
      flex-shrink: 0;
      .van-tabs__nav {
        border: 0px solid #f0a;
      }
    }
    .van-tabs__content {
      height: 100%;
      padding: 0;
      overflow-y: auto;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
</style>
